let devEnvConf: EnvConf = {} as EnvConf;

// NOTE: When adding variables here to forget to also add them in the server part so we actually inject the variable into the html at runtime.
// This is the relevant file: server/env-setup.js
interface EnvConf {
  API_BASE_URL: string;
  AUTH0_DOMAIN: string;
  AUTH0_CLIENT_ID: string;
  AUTH0_ENV_URI: string;
  AUTH0_AUDIENCE: string;
  COMMIT_SHA: string;
  APP_ENV: string;
  INTERCOM_KEY?: string;
  LANGUAGE_KEY_ALIGNMENT_TOUR_SV: string;
  LANGUAGE_KEY_ALIGNMENT_TOUR_EN: string;
  GA4_ID?: string;
  ACADEMY_BASE_URL: string;
  WHY_PAGE_DISABLED?: string;
  HELPCENTER_BASE_URL: string;
  ACTIVE_TEAMS_GRAPH_GA?: string;
  ACTIVE_TEAMS_GRAPH_TENANTS?: string;
  AI_DEMO_GA?: string;
  AI_DEMO_TENANTS?: string;
  AI_SUGGEST_GA?: string;
  AI_SUGGEST_TENANTS?: string;
  PURE_KEY_ACTIVITIES_GA?: string;
  PURE_KEY_ACTIVITIES_TENANTS?: string;
  PURE_KEY_ACTIVITIES_STEP2_GA?: string;
  PURE_KEY_ACTIVITIES_STEP2_TENANTS?: string;
}

type EnvironmentName = 'integration' | 'qa' | 'production' | 'demo';

type DevEnvConfOptions = Record<EnvironmentName, EnvConf>;

if (import.meta.env.MODE !== 'production') {
  const localGql = import.meta.env.VITE_LOCAL_GQL === 'true';

  const envConf: DevEnvConfOptions = {
    integration: {
      API_BASE_URL: localGql
        ? 'http://localhost:4000'
        : 'https://graphql-integration.howwe.dev',
      AUTH0_DOMAIN: 'howwe-integration.eu.auth0.com',
      AUTH0_CLIENT_ID: 'Vh65KDg11Z8Z4G89zriEtzQk71xDmNv1',
      AUTH0_ENV_URI: 'http://localhost:3001',
      AUTH0_AUDIENCE: 'http://api-integration.howwe.dev',
      COMMIT_SHA: 'N/A',
      APP_ENV: 'integration',
      ACADEMY_BASE_URL: 'https://academy.howwe.io',
      LANGUAGE_KEY_ALIGNMENT_TOUR_SV: '291359',
      LANGUAGE_KEY_ALIGNMENT_TOUR_EN: '291355',
      HELPCENTER_BASE_URL: 'https://help.howwe.io',
      ACTIVE_TEAMS_GRAPH_GA: 'true',
      GA4_ID: 'G-7K3Z1JXDT7',
      AI_SUGGEST_GA: 'true',
      PURE_KEY_ACTIVITIES_GA: 'true',
      PURE_KEY_ACTIVITIES_STEP2_GA: 'true',
    },
    qa: {
      API_BASE_URL: localGql
        ? 'http://localhost:4000'
        : 'https://graphql-qa.howwe.dev',
      AUTH0_DOMAIN: 'login.howwe.dev',
      AUTH0_CLIENT_ID: 'GCgxlHPZaRmSxnkgVDhodNbMbfBrgYhV',
      AUTH0_ENV_URI: 'http://localhost:3001',
      AUTH0_AUDIENCE: 'http://api-qa.howwe.dev',
      COMMIT_SHA: 'N/A',
      APP_ENV: 'qa',
      INTERCOM_KEY: 'z14twp5n',
      ACADEMY_BASE_URL: 'https://academy.howwe.io',
      LANGUAGE_KEY_ALIGNMENT_TOUR_SV: '291359',
      LANGUAGE_KEY_ALIGNMENT_TOUR_EN: '291355',
      HELPCENTER_BASE_URL: 'https://help.howwe.io',
      AI_DEMO_GA: 'true',
      AI_SUGGEST_GA: 'true',
      PURE_KEY_ACTIVITIES_GA: 'true',
      PURE_KEY_ACTIVITIES_STEP2_GA: 'true',
    },
    production: {
      API_BASE_URL: localGql
        ? 'http://localhost:4000'
        : 'https://graphql.howwe.io',
      AUTH0_DOMAIN: 'login.howwe.io',
      AUTH0_CLIENT_ID: '8G57eDUUXswXqEhRJjaNtZtkgd1Se5ws',
      AUTH0_ENV_URI: 'http://localhost:3001',
      AUTH0_AUDIENCE: 'http://api.howwe.io',
      COMMIT_SHA: 'N/A',
      APP_ENV: 'production',
      ACADEMY_BASE_URL: 'https://academy.howwe.io',
      LANGUAGE_KEY_ALIGNMENT_TOUR_SV: '291359',
      LANGUAGE_KEY_ALIGNMENT_TOUR_EN: '291355',
      HELPCENTER_BASE_URL: 'https://help.howwe.io',
      PURE_KEY_ACTIVITIES_GA: 'true',
      PURE_KEY_ACTIVITIES_STEP2_GA: 'true',
    },
    demo: {
      API_BASE_URL: localGql
        ? 'http://localhost:4000'
        : 'https://graphql-demo.howwe.dev',
      AUTH0_DOMAIN: 'howwe-demo.eu.auth0.com',
      AUTH0_CLIENT_ID: '4kDcUNpzrhkaJguVt4ou4vVCx7A34oBz',
      AUTH0_ENV_URI: 'http://localhost:3001',
      AUTH0_AUDIENCE: 'http://api-demo.howwe.dev',
      COMMIT_SHA: 'N/A',
      APP_ENV: 'demo',
      ACADEMY_BASE_URL: 'https://academy.howwe.io',
      LANGUAGE_KEY_ALIGNMENT_TOUR_SV: '291359',
      LANGUAGE_KEY_ALIGNMENT_TOUR_EN: '291355',
      HELPCENTER_BASE_URL: 'https://help.howwe.io',
      AI_DEMO_GA: 'true',
    },
  };

  type EnvConfKey = keyof typeof envConf;
  // The target env is set by scripts in package.json when starting the dev server.
  // Will default to the integration environment if no environment is specified.
  // Do note however that the default `npm start` command will use QA as target environment.
  const targetEnv = import.meta.env.VITE_TARGET_ENV as EnvConfKey;
  devEnvConf = targetEnv ? envConf[targetEnv] : envConf.integration;
}

// environment conf (windows.envConf) for productions builds are injected
// into a script tag in index.html by the server (/server.js)
export const envConf: EnvConf =
  import.meta.env.MODE === 'production' ? (window as any).envConf : devEnvConf;
// *Note* that `import.meta.env.MODE === 'production'` doesn't mean Production environment,
// it just means it has been built, i.e. not running via dev server
