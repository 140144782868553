import { gql } from '@apollo/client';
import { ActivityMilestone_MilestoneWithLinksForTeamFragment } from '../../../../generated/graphql';
import { MilestoneStatusIcon } from '../../../../components/MilestoneStatusIcon';
import './ActivityMilestone.less';
import { MilestoneCardActivityStats } from '../../sprint/planning/components/mitemPlanningBoard/milestoneCard/MilestoneCardActivityStats';
import { Suspense } from 'react';
import { InitiativeIcon } from '../../../../components/initiative/InitiativeIcon';
import { ActivityMilestoneSkeleton } from './ActivityMilestone.Skeleton';
import cx from 'classnames';

interface Props {
  teamId: string;
  milestone: ActivityMilestone_MilestoneWithLinksForTeamFragment;
  onClick?: () => void;
  className?: string;
}

export const ActivityMilestone = ({
  teamId,
  milestone,
  onClick,
  className,
}: Props) => {
  return (
    <div
      onClick={onClick}
      className={cx(
        `card ActivityMilestone ActivityMilestone--${milestone.metadata.status}`,
        className
      )}
    >
      <div className="flx">
        <div className="mr">
          <MilestoneStatusIcon metadata={milestone.metadata} />
        </div>
        <h5>{milestone.name}</h5>
      </div>
      <div>
        <div>
          {milestone.metadata.supportsInitiatives.map((initiative) => (
            <div
              key={initiative.id}
              className="flx  txt--secondary font-size--sm"
            >
              <div className="space--l">
                <InitiativeIcon
                  key={initiative.id}
                  iconId={initiative.data.tag.iconId}
                />
              </div>
              <div className="ml--m">{initiative.data.tag.title}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="divider mt--s mb--s" />
      <Suspense fallback={<div>Loading...</div>}>
        <div className="flx">
          <div className="mr--s txt--secondary font-size--sm">Activities:</div>
          <MilestoneCardActivityStats
            milestoneId={milestone.domainId.itemId}
            teamId={teamId}
            fontSize={12}
          />
        </div>
      </Suspense>
    </div>
  );
};

ActivityMilestone.Skeleton = ActivityMilestoneSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACTIVITY_MILESTONE_MILESTONE_WITH_LINKS = gql`
  fragment ActivityMilestone_MilestoneWithLinksForTeam on MilestoneWithLinks {
    id
    domainId {
      itemId
      tenantId
    }
    name
    description
    deadlineAt
    metadata {
      completedAt
      status
      supportsInitiatives {
        id
        domainId {
          itemId
          tenantId
        }
        data {
          id
          domainId {
            itemId
          }
          name
          tag {
            title
            iconId
            colorCode
          }
        }
      }
    }
    ...MilestoneStatusIcon_Milestone
  }
`;
