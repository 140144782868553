import { gql } from '@apollo/client';
import { CollapseCard } from '../overview/components/collapseCard/CollapseCard';

import { useState } from 'react';
import { InitiativeSectionCard_InitiativeWithLinksFragment } from '../../../generated/graphql';
import { InitiativeIcon } from '../../../components/initiative/InitiativeIcon';
import { Tabs, TabsProps } from 'antd';
import { MilestonePlaner } from './MilestonePlaner';

interface Props {
  teamId: string;
  initiative: InitiativeSectionCard_InitiativeWithLinksFragment;
  className?: string;
}

export const InitiativeSectionCard = ({
  teamId,
  initiative,
  className,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Milestone execution plan',
      children: (
        <MilestonePlaner
          initiative={initiative}
          teamId={teamId}
          isExpanded={isExpanded}
        />
      ),
    },
    {
      key: '2',
      label: 'Directly Connected activities',
      children: <div style={{ minHeight: 300 }}>Content of Tab Pane 2</div>,
    },
    {
      key: '3',
      label: 'Initiative Details',
      children: 'Content of Tab Pane 3',
    },
  ];
  return (
    <CollapseCard
      className={className}
      headerContent={
        <div>
          <h3>{initiative.name}</h3>
          <div className="flx flx--ai-center">
            <InitiativeIcon iconId={initiative.tag.iconId} />
            <div className="ml--s">{initiative.tag.title}</div>
          </div>
        </div>
      }
      onClick={() => setIsExpanded(!isExpanded)}
      expanded={isExpanded}
    >
      <Tabs className="fullWidth pa" defaultActiveKey="1" items={items} />
    </CollapseCard>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_SECTION_CARD_InitiativeWithLinksFragment = gql`
  fragment InitiativeSectionCard_InitiativeWithLinks on InitiativeWithLinks {
    id
    domainId {
      itemId
    }
    name
    tag {
      title
      colorCode
      iconId
    }
    ...MilestonePlaner_InitiativeWithLinks
  }
`;
