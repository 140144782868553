import { gql, useQuery } from '@apollo/client';
import { GetInitsForInitiativeSelectFormItemDocument } from '../../generated/graphql';
import { InitiativeTag } from '../initiative/InitiativeTag';

interface Props {
  teamId: string;
  value?: string;
  onChange?: (value: string) => void;
}

export const InitiativeSelectFormItem = ({
  teamId,
  value,
  onChange,
}: Props) => {
  const { data: initiativeData } = useQuery(
    GetInitsForInitiativeSelectFormItemDocument,
    {
      variables: { teamId },
    }
  );

  const initiatives = initiativeData?.initiativesLinkedToTeam.initiatives ?? [];

  return (
    <div className="flx flx--gap--s flx--wrap">
      {initiatives.map((initiative) => (
        <div
          className="clickable"
          key={initiative.id}
          onClick={() => onChange?.(initiative.domainId.itemId)}
        >
          <InitiativeTag
            useBgColor={initiative.domainId.itemId === value}
            tag={initiative.tag}
            completed={initiative.metadata.completedAt !== null}
            archived={initiative.metadata.archived}
          />
        </div>
      ))}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MITEM_FORM_INITIATIVES_FOR_TEAM = gql`
  query GetInitsForInitiativeSelectFormItem($tenantId: ID, $teamId: ID!) {
    initiativesLinkedToTeam(tenantId: $tenantId, teamId: $teamId) {
      initiatives {
        ...InitiativeSelectFormItem_initiative
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_FRAGMENT = gql`
  fragment InitiativeSelectFormItem_initiative on InitiativeWithLinks {
    id
    domainId {
      itemId
    }
    tag {
      title
      iconId
      colorCode
    }
    metadata {
      completedAt
      status
      archived
    }
  }
`;
