import { gql, useQuery } from '@apollo/client';
import { InitiativeSectionCard } from './InitiativeSectionCard';
import { TeamInitiativesForActivityPlanerDocument } from '../../../generated/graphql';

interface Props {
  teamId: string;
}

export const ActivityPlanner: React.FC<Props> = ({ teamId }) => {
  const { data } = useQuery(TeamInitiativesForActivityPlanerDocument, {
    variables: { teamId },
  });
  const initiatives = data?.initiativesLinkedToTeam?.initiatives ?? [];
  return (
    <div className="maxWidth">
      <h1 className="mb">Activity Planner</h1>

      {initiatives.map((initiative) => (
        <InitiativeSectionCard
          key={initiative.id}
          teamId={teamId}
          initiative={initiative}
          className="mb"
        />
      ))}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACTIVITY_PLANER_QUERY = gql`
  query TeamInitiativesForActivityPlaner($teamId: ID!) {
    initiativesLinkedToTeam(teamId: $teamId) {
      initiatives {
        id
        domainId {
          itemId
        }
        name
        tag {
          title
          colorCode
          iconId
        }
        ...InitiativeSectionCard_InitiativeWithLinks
      }
    }
  }
`;
