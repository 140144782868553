import React, { createContext, useContext, useState, useCallback } from 'react';
import { CreateMitemDrawer } from './appPages/team/sprint/planning/components/CreateMitemDrawer';
import { EditSprintKeyActivityDrawer } from './appPages/team/sprint/planning/components/mitemListEntry/EditSprintKeyActivityDrawer';
import { EditSprintKeyActivityDrawer__MitemFragment } from './generated/graphql';
import { CreateActivityModal } from './components/activities/CreateActivityModal';

interface ModalContextType {
  openCreateActivity: (teamId: string, prefilledValues?: any) => void;
  openEditActivity: (
    activity: EditSprintKeyActivityDrawer__MitemFragment
  ) => void;
  closeModal: () => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [modalState, setModalState] = useState<
    | {
        type: 'edit';
        prefilledValues?: any;
        activity: EditSprintKeyActivityDrawer__MitemFragment;
      }
    | {
        type: 'create';
        teamId: string;
        activityId?: string;
        prefilledValues?: any;
      }
    | null
  >(null);

  const openCreateActivity = useCallback(
    (teamId: string, prefilledValues?: any) => {
      setModalState({
        type: 'create',
        teamId,
        prefilledValues,
      });
    },
    []
  );

  const openEditActivity = useCallback(
    (activity: EditSprintKeyActivityDrawer__MitemFragment) => {
      setModalState({
        type: 'edit',
        activity,
      });
    },
    []
  );

  const closeModal = useCallback(() => {
    setModalState(null);
  }, []);

  return (
    <ModalContext.Provider
      value={{ openCreateActivity, openEditActivity, closeModal }}
    >
      {children}
      {modalState?.type === 'create' && (
        <CreateMitemDrawer
          teamId={modalState.teamId}
          showHelpLinks={false}
          showModal={true}
          onCancel={closeModal}
          prefilledValues={modalState.prefilledValues}
          onCompleted={closeModal}
        />
      )}
      {modalState?.type === 'edit' && (
        <EditSprintKeyActivityDrawer
          mitem={modalState.activity}
          showModal={true}
          onCancel={closeModal}
          //prefilledValues={modalState.prefilledValues}
          onCompleted={closeModal}
        />
      )}
      <CreateActivityModal
        teamId="7f152701-40a6-48ce-ad39-2b13dd0f3b55"
        open={false}
        onCancel={() => {}}
      />
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
