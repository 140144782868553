import { ArrowDownOutlined } from '@ant-design/icons';
import { useQuery, gql, useLazyQuery, useSuspenseQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Btn } from '../../../../../../components/Button';

import {
  GetSprintKeyActivitiesForMilestoneActivityListDocument,
  GetActivitiesForActivityCommitmentStepDocument,
  ActivityListMilestoneQueryDocument,
  GetOverdueActivitiesForActivityCommitmentStepDocument,
  MitemStatus,
} from '../../../../../../generated/graphql';
import { ActivityTable } from '../../../../activities/listPage/ActivityTable/ActivityTable';
import { CreateMitemDrawer } from '../../../../sprint/planning/components/CreateMitemDrawer';
import { groupDeadlinesByMonth } from '../../../../activities/TeamActivitiesRoutes';
import { useActivityList } from '../../../../activities/hooks/useActivityList';
import { NoMilestoneActivities } from '../../../../activities/components/NoMilestoneActivities';
import { MilestoneSidebar } from '../../../../activities/listPage/MilestoneSidebar';
import { ActivityCommitmentStepSkeleton } from './ActivityCommitmentStep.Skeleton';

interface Props {
  teamId: string;
}

export const ActivityCommitmentStep = ({ teamId }: Props) => {
  const startDate = dayjs().startOf('month');
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);

  const [showCreateActivityModal, setShowCreateActivityModal] = useState(false);

  const { data, fetchMore, loading } = useQuery(
    GetActivitiesForActivityCommitmentStepDocument,
    {
      variables: {
        teamId: teamId,
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: startDate.add(4, 'month').format('YYYY-MM-DD'),
      },
      fetchPolicy: 'cache-first',
      nextFetchPolicy: 'cache-only',
    }
  );

  const overdueActivitiesData = useSuspenseQuery(
    GetOverdueActivitiesForActivityCommitmentStepDocument,
    {
      variables: {
        teamId: teamId,
        endDate: startDate
          .subtract(1, 'month')
          .endOf('month')
          .format('YYYY-MM-DD'),
      },
    }
  );

  const overdueActivities =
    overdueActivitiesData.data?.sprintKeyActivities.sprintKeyActivities?.filter(
      (a) => a.status == MitemStatus.OVERDUE
    ) ?? [];

  const { data: milestoneData, loading: milestonesLoading } = useQuery(
    ActivityListMilestoneQueryDocument,
    {
      variables: {
        teamId: teamId,
      },
    }
  );

  const [
    fetchActivitiesForMilestone,
    {
      data: activitiesForMilestoneData,
      loading: activitiesForMilestoneLoading,
    },
  ] = useLazyQuery(GetSprintKeyActivitiesForMilestoneActivityListDocument);

  const activityList = useActivityList(
    startDate,
    activitiesForMilestoneData?.sprintKeyActivitiesForMilestone
      .sprintKeyActivitiesForTeam,
    milestoneData?.milestonesWithLinksForTeam.milestones
  );

  const groupedActivities = activityList.selectedMilestone
    ? groupDeadlinesByMonth(activityList.activitiesForMilestone)
    : groupDeadlinesByMonth(
        data?.sprintKeyActivities.sprintKeyActivities ?? []
      );

  return (
    <div>
      <div className="mt--xl flx maxWidth">
        <div className="flx--1 ActivityList__main">
          <h3>Activities</h3>
          {(activitiesForMilestoneLoading || loading) && (
            <>
              <ActivityTable.Skeleton showActions />
              <ActivityTable.Skeleton />
            </>
          )}
          {!activitiesForMilestoneLoading &&
            activityList.selectedMilestone &&
            activityList.monthsToDisplay.length === 0 && (
              <NoMilestoneActivities
                onOpenCreateActivityModal={() =>
                  setShowCreateActivityModal(true)
                }
              />
            )}
          {!loading && overdueActivities.length !== 0 && (
            <ActivityTable
              teamId={teamId}
              committable={true}
              className="mt--xl"
              activities={overdueActivities}
              header={
                <h4 className="mb--s">
                  Overdue activities from previous months
                </h4>
              }
            />
          )}
          {!activitiesForMilestoneLoading &&
            !loading &&
            activityList.monthsToDisplay.map((monthKey) => (
              <ActivityTable
                teamId={teamId}
                committable={true}
                key={monthKey}
                className="mt--xl"
                activities={groupedActivities.get(monthKey) ?? []}
                header={
                  <ActivityTable.Header
                    startDate={dayjs(monthKey)}
                    actions={
                      <div className="flx">
                        <Btn
                          size="small"
                          type="link"
                          className="ml--auto"
                          onClick={() => setShowCreateActivityModal(true)}
                        >
                          + Add Activity
                        </Btn>
                      </div>
                    }
                  />
                }
              />
            ))}
          <Btn
            loading={fetchMoreLoading}
            disabled={activityList.selectedMilestone != null}
            icon={<ArrowDownOutlined />}
            size="small"
            className="mt"
            onClick={() => {
              const nextMonth = activityList.monthRange.end.add(1, 'months');
              fetchMore({
                variables: {
                  teamId: teamId,
                  startDate: nextMonth.startOf('month').format('YYYY-MM-DD'),
                  endDate: nextMonth.endOf('month').format('YYYY-MM-DD'),
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  setFetchMoreLoading(false);
                  if (!fetchMoreResult) return prev;

                  const existingActivities =
                    prev.sprintKeyActivities.sprintKeyActivities ?? [];
                  const newActivities =
                    fetchMoreResult.sprintKeyActivities.sprintKeyActivities ??
                    [];

                  const existingIds = new Set(
                    existingActivities.map((a) => a.id)
                  );
                  const uniqueNewActivities = newActivities.filter(
                    (a) => !existingIds.has(a.id)
                  );
                  return {
                    __typename: prev.__typename,
                    sprintKeyActivities: {
                      __typename: prev.sprintKeyActivities.__typename,
                      sprintKeyActivities: [
                        ...existingActivities,
                        ...uniqueNewActivities,
                      ],
                    },
                  };
                },
              }).then(() => {
                activityList.setMonthRange({
                  ...activityList.monthRange,
                  end: nextMonth.endOf('month'),
                });
              });
            }}
          >
            Load More
          </Btn>
        </div>
        <div className="ActivityList__sidebar">
          <MilestoneSidebar
            teamId={teamId}
            milestonesLoading={milestonesLoading}
            milestonesByMonth={activityList.milestonesByMonth}
            selectedMilestone={activityList.selectedMilestone}
            onSelectMilestone={(milestoneId) => {
              activityList.selectMilestone(milestoneId);
              if (milestoneId) {
                fetchActivitiesForMilestone({
                  variables: { milestoneId, teamId },
                });
              }
            }}
          />
        </div>
      </div>
      <CreateMitemDrawer
        teamId={teamId}
        showHelpLinks={false}
        showModal={showCreateActivityModal}
        onCancel={() => {
          setShowCreateActivityModal(false);
        }}
        onCompleted={() => {
          setShowCreateActivityModal(false);
        }}
        prefilledValues={
          activityList.selectedMilestone && {
            supportsMilestoneLinks: [
              {
                id: activityList.selectedMilestone.id,
                domainId: activityList.selectedMilestone.domainId,
                data: activityList.selectedMilestone,
              },
            ],
          }
        }
        refetchQueries={
          activityList.selectedMilestone && [
            {
              query: GetSprintKeyActivitiesForMilestoneActivityListDocument,
              variables: {
                milestoneId: activityList.selectedMilestone.domainId.itemId,
              },
            },
          ]
        }
      />
    </div>
  );
};
ActivityCommitmentStep.Skeleton = ActivityCommitmentStepSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACTIVITY_LIST__MITEM = gql`
  fragment ActivityCommitmentStep_Mitem on Mitem {
    id
    name
    deadline
    owner2 {
      id
      name
      email
      initials
      displayName
      domainId {
        itemId
      }
    }
    ...ActivityTable_Mitem
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ActivityCommitmentStep_Milestones = gql`
  query ActivityCommitmentStepMilestoneQuery($teamId: ID!) {
    milestonesWithLinksForTeam(teamId: $teamId) {
      milestones {
        id
        domainId {
          itemId
          tenantId
        }
        name
        assignedTo {
          id
          data {
            id
            domainId {
              itemId
            }
            name
            email
            displayName
          }
        }
        description
        deadlineAt
        metadata {
          completedAt
          status
          supportsInitiatives {
            id
            domainId {
              itemId
              tenantId
            }
          }
        }
        ...MilestoneSidebar_MilestoneWithLinks
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_SPRINT_KEY_ACTIVITIES_FOR_ACTIVITY_LIST = gql`
  query GetSprintKeyActivitiesForMilestoneActivityCommitmentStep(
    $milestoneId: ID!
    $teamId: ID!
  ) {
    sprintKeyActivitiesForMilestone(milestoneId: $milestoneId) {
      sprintKeyActivitiesForTeam(teamId: $teamId) {
        id
        ...TeamActivitiesRoutes_Mitem
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_MITEMS = gql`
  query GetActivitiesForActivityCommitmentStep(
    $teamId: ID!
    $startDate: String
    $endDate: String
    $archived: Boolean
  ) {
    sprintKeyActivities(
      teamId: $teamId
      archived: $archived
      startDate: $startDate
      endDate: $endDate
    ) {
      sprintKeyActivities {
        id
        ...ActivityCommitmentStep_Mitem
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_OVERDUE_MITEMS = gql`
  query GetOverdueActivitiesForActivityCommitmentStep(
    $teamId: ID!
    $startDate: String
    $endDate: String
  ) {
    sprintKeyActivities(
      teamId: $teamId
      overdue: true
      startDate: $startDate
      endDate: $endDate
    ) {
      sprintKeyActivities {
        id
        ...ActivityCommitmentStep_Mitem
      }
    }
  }
`;
