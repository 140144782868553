import { Input, DatePicker, Select, Form } from 'antd';
import './CreateActivity.less';
import { skaValidationRules } from '../../appPages/team/common/utils/useSkaValidationRules';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';
import {
  ActivityForm_MitemFragment,
  GetTeamMembersForActivityFormDocument,
} from '../../generated/graphql';
import { RecursiveOmit } from '../../services/typeHelpers';
import { TitleIcon } from '../initiatives/InitiativeForm/Icons/TitleIcon';
import { AlignmentIcon } from '../initiatives/InitiativeForm/Icons/AlignmentIcon';
import { useTenantDetails } from '../../hooks/useTenantDetails';
import { FlagOutlined } from '@ant-design/icons';
import { EndingPointIcon } from '../initiatives/InitiativeForm/Icons/EndingPointIcon';
import { friendlyUsername } from '../../services/friendlyUsername';
import { PersonSearchIcon } from '../initiatives/InitiativeForm/Icons/PersonSearchIcon';
import { TextAreaIconIcon } from '../initiatives/InitiativeForm/Icons/TextAreaIcon';
import { InitiativeSelectFormItem } from './InitiativeSelectFormItem';
import { UserAvatar } from '../UserAvatar';
import { DisplayName } from '../DisplayName';
import { Btn } from '../Button';
import { MilestoneSelectFormItem } from './MilestoneSelectFormItem';

interface Props {
  initialValues?: SkaInitialValues;
  submitPending?: boolean;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  setIsFormDirty?: (dirty: boolean) => void;
  isEdit?: boolean;
  teamId: string;
}

export const CreateActivity = ({
  teamId,
  initialValues,
  onSubmit,
  onCancel,
}: Props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { features } = useTenantDetails();

  const { data: membersData, loading: loadingMembers } = useQuery(
    GetTeamMembersForActivityFormDocument,
    {
      variables: { teamId },
    }
  );
  const members = membersData?.team2?.members ?? [];
  const keyActivityOwnerId = Form.useWatch('ownerId', form);

  const isOwnerMemberOfTeam =
    keyActivityOwnerId == null ||
    members.some((u) => u.user.domainId.itemId === keyActivityOwnerId);

  const selectedInitiative = Form.useWatch('supportsInitiativeIds', form);

  return (
    <div>
      <Form
        className="mb--xxl"
        form={form}
        autoComplete="off"
        requiredMark={false}
        name="create_activity_form"
        colon={false}
      >
        <Form.Item
          name="name"
          label={<TitleIcon className="font-size--lg txt--secondary" />}
          required
          rules={skaValidationRules.name}
          data-intercom-target="Sprint Key Activity Form Name Input"
        >
          <Input autoComplete="off" autoFocus placeholder={t('common.title')} />
        </Form.Item>

        {features.tenantInitiativesEnabled && (
          <Form.Item
            name="supportsInitiativeIds"
            label={<AlignmentIcon className="font-size--lg txt--secondary" />}
          >
            <InitiativeSelectFormItem teamId={teamId} />
          </Form.Item>
        )}

        <Form.Item
          label={<FlagOutlined className="font-size--lg txt--secondary" />}
          name="milestone"
        >
          <MilestoneSelectFormItem
            teamId={teamId}
            selectedInitiativeId={selectedInitiative}
          />
        </Form.Item>

        <div className="flx flx--gap">
          <Form.Item
            name="deadline"
            label={<EndingPointIcon className="font-size--lg txt--secondary" />}
            required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker
              picker="date"
              className="fullWidth"
              placeholder={t('common.deadline')}
            />
          </Form.Item>

          <Form.Item
            name="ownerId"
            label={
              <PersonSearchIcon className="font-size--lg txt--secondary" />
            }
            className="flx--1"
            extra={
              !isOwnerMemberOfTeam &&
              t('SprintKaForm.notMember') /* TODO: fix */
            }
            required
            rules={skaValidationRules.ownerId}
            data-intercom-target="Sprint Key Activity Form Owner Input"
          >
            <Select
              placeholder={t('common.owner')}
              showSearch
              options={members.map((m) => ({
                label: (
                  <div>
                    <span className="mr--s">
                      <UserAvatar user={m.user.data} size="small" />
                    </span>
                    <DisplayName user={m.user.data} />
                  </div>
                ),
                value: m.user.domainId.itemId,
                userName: friendlyUsername(m.user.data),
              }))}
              optionFilterProp="userName"
              filterOption={(inputValue, option) =>
                option?.userName
                  .toLocaleLowerCase()
                  .includes(inputValue.toLocaleLowerCase()) ?? false
              }
            />
          </Form.Item>
        </div>

        <Form.Item
          name="definitionOfDone"
          label={<TextAreaIconIcon className="font-size--lg txt--secondary" />}
          rules={skaValidationRules.definitionOfDone}
          data-intercom-target="Sprint Key Activity Form Definition of Done Input"
        >
          <Input.TextArea
            placeholder={t('common.definitionOfDone')}
            autoSize={{ minRows: 2, maxRows: 10 }}
            maxLength={1024}
            showCount
          />
        </Form.Item>
      </Form>

      <div className="flx flx--jc-flx-end borderTop pt--l">
        <Btn type="link">More options</Btn>
        <Btn type="primary" htmlType="submit">
          Create Activity
        </Btn>
      </div>
    </div>
  );
};

export type SkaInitialValues = Partial<
  RecursiveOmit<ActivityForm_MitemFragment, '__typename'>
>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_KA_FORM__GET_MEMBERS = gql`
  query GetTeamMembersForActivityForm($teamId: ID!) {
    team2(teamId: $teamId) {
      archived
      name
      id
      domainId {
        tenantId
        itemId
      }
      rev
      members {
        roles
        user {
          id
          domainId {
            itemId
          }
          data {
            id
            domainId {
              itemId
            }
            name
            email
            displayName
            initials
          }
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACTIVITY_FORM_MITEM = gql`
  fragment ActivityForm_Mitem on Mitem {
    id
    name
    archived
    archivedAt
    completed
    completedAt
    deadline
    definitionOfDone
    noMigAssociation
    milestone
    supportsMilestoneLinks {
      id
      domainId {
        itemId
      }
      data {
        id
        domainId {
          itemId
          tenantId
        }
        name
        deadlineAt
        metadata {
          completedAt
          archived
        }
      }
    }
    supportsInitiativeLinks {
      id
      domainId {
        itemId
      }
      data {
        id
        ...MitemFormTenantInitiativeFormItem_initiative
      }
    }
    status
    tags {
      id
      name
      teamId
      active
      backgroundColor
      createdAt
    }
    owner2 {
      id
      domainId {
        itemId
      }
      email
      initials
      name
      displayName
      archived
    }
    supportedMigs {
      id
      name
      domainId {
        itemId
        teamId
      }
    }
    teamId
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_QUERY = gql`
  query ActivityForm_MilestoneQuery($milestoneId: ID!) {
    milestoneWithLinks(milestoneId: $milestoneId) {
      id
      domainId {
        itemId
        tenantId
      }
      name
      metadata {
        completedAt
        archived
        supportsInitiatives {
          id
          domainId {
            itemId
          }
        }
      }
    }
  }
`;
