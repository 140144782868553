import { gql, useLazyQuery } from '@apollo/client';
import {
  GetMilestonesForMilestonePlanerDocument,
  MilestonePlaner_InitiativeWithLinksFragment,
} from '../../../generated/graphql';
import { MilestoneColumn } from './MilestoneColumn';
import { useEffect } from 'react';
import { stringSort } from '../../../services/stringSort';

interface Props {
  teamId: string;
  initiative: MilestonePlaner_InitiativeWithLinksFragment;
  isExpanded: boolean;
}

export const MilestonePlaner = ({ teamId, initiative, isExpanded }: Props) => {
  const [loadMilestones, { data }] = useLazyQuery(
    GetMilestonesForMilestonePlanerDocument,
    {
      variables: { initiativeId: initiative.domainId.itemId },
    }
  );

  useEffect(() => {
    if (isExpanded) {
      loadMilestones();
    }
  }, [isExpanded, loadMilestones]);

  return (
    <div style={{ overflowX: 'scroll' }}>
      <div className="flx">
        {data?.milestonesForInitiative.milestones
          .sort((a, b) => stringSort(a.deadlineAt, b.deadlineAt))
          .map((m) => (
            <MilestoneColumn key={m.id} milestone={m} teamId={teamId} />
          ))}
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_MILESTONES_FOR_MILESTONE_PLANER = gql`
  query getMilestonesForMilestonePlaner($tenantId: ID, $initiativeId: ID!) {
    milestonesForInitiative(tenantId: $tenantId, initiativeId: $initiativeId) {
      milestones {
        id
        name
        description
        deadlineAt
        assignedTo {
          id
          domainId {
            itemId
          }
          data {
            id
            name
            email
            displayName
            initials
          }
        }
        metadata {
          status
        }
        ...MilestoneColumn_MilestoneWithLinksForTeam
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_PLANER_INITIATIVE_FRAGMENT = gql`
  fragment MilestonePlaner_InitiativeWithLinks on InitiativeWithLinks {
    id
    domainId {
      itemId
    }
  }
`;
