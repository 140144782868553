import { match } from 'ts-pattern';
import { SimpleStatus } from '../services/mitemUtils';
import { CheckIcon } from '../icons/overviewIcons/CheckIcon';
import { WarningIcon } from '../icons/performanceIcons/WarningIcon';
import { UpcomingEventIcon } from '../icons/overviewIcons/UpcomingEventIcon';

interface Props {
  status: SimpleStatus;
  size?: number;
}

export const KeyActivityStatusIcon = ({ status, size = 14 }: Props) => {
  const statusTag = match(status)
    .with('COMPLETED', () => ({
      icon: CheckIcon,
      className: 'txt--success',
    }))
    .with('OVERDUE', () => ({
      icon: WarningIcon,
      className: 'txt--warning',
    }))
    .with('ARCHIVED', () => ({
      icon: UpcomingEventIcon,
      className: 'txt--secondary',
    }))
    .with('UPCOMING', () => ({
      icon: UpcomingEventIcon,
      className: 'txt--planned',
    }))
    .exhaustive();

  return (
    <statusTag.icon
      className={statusTag.className}
      style={{ fontSize: size }}
    />
  );
};
