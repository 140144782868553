import { Modal } from 'antd';
import { CreateActivity } from './CreateActivity';

interface Props {
  open: boolean;
  teamId: string;
  onCancel: () => void;
}

export const CreateActivityModal = ({ open, teamId, onCancel }: Props) => {
  return (
    <Modal
      open={open}
      title={<h3 className="mb--l">Create Activity</h3>}
      okText="Create Activity"
      cancelText="Cancel"
      footer={null}
      onCancel={onCancel}
      onOk={() => {
        // form
        //   .validateFields()
        //   .then((values) => {
        //     form.resetFields();
        //     onCreate(values);
        //   })
        //   .catch((info) => {
        //     console.log('Validate Failed:', info);
        //   });
      }}
    >
      <CreateActivity teamId={teamId} onSubmit={() => {}} onCancel={onCancel} />
    </Modal>
  );
};
